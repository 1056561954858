import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Button,
  Center
} from "@chakra-ui/react"
import theme from "./theme"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"


interface AppProps {

}

interface AppState {
    count: number;
}


export class App extends React.Component<AppProps, AppState> {

	state: AppState = {
        count: 0
    }

    handleClick = () => {
        const newCount = this.state.count + 1;
        this.setState({
            count: newCount
        })
    }

	render() {
		return (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        { /* <ColorModeSwitcher justifySelf="flex-end" /> */ }
        <VStack spacing={8} align="center">
			<Center h="100%">
          <Logo h="60vmin" pointerEvents="auto" />
		  </Center>
		  { /*
		  <Button onClick={this.handleClick}>Click me!</Button>
          <Text>
            You clicked <Code fontSize="xl">{this.state.count}</Code> times!
          </Text>
          <Link
            color="teal.500"
            href="https://tinkatinka.com"
            fontSize="2xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            TinkaTinka
          </Link> */ }
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
		);
	}

}
