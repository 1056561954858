import * as React from "react"
import {
	chakra,
	keyframes,
	ImageProps,
	forwardRef,
	usePrefersReducedMotion,
	useColorModeValue
} from "@chakra-ui/react"
// import logo from "./logo.svg"
import eyes1 from './eyes09.png';
import eyes2 from './eyes10.png';
import eyes3 from './eyes11.png';
const spin = keyframes`
  0% { transform: rotate(0deg) scale(1.0); }
  50% { transform: rotate(180deg) scale(2.0); }
  100% { transform: rotate(360deg) scale(1.0); }
`

const eyes = [eyes1, eyes2, eyes3 ];

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
	const [srcIdx, setSrcIdx] = React.useState(0);
	const toggleSrc = () => {
		setSrcIdx((srcIdx + 1) % eyes.length);
	};
	const prefersReducedMotion = usePrefersReducedMotion()

	const animation = prefersReducedMotion
		? undefined
		: `${spin} infinite 15s linear`

	const style = useColorModeValue({}, {}); // filter: "invert(.75)"});

	return <chakra.img animation={animation} src={eyes[srcIdx]} ref={ref} style={style} onClick={toggleSrc} {...props} />
})
