import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      body: {
        bg: "#000000",
        color: "#ffffff",
        height: "100vh",
      },
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
  colors: {
    brand: {
      100: "#c0edde",
      200: "#9ddfc9",
      300: "#78d1b4",
      400: "#54c49f",
      500: "#339E7A",
      600: "#2b8568",
      900: "#1a202c",
    },
  },
  fonts: {
    heading: '"Karla", sans-serif',
    body: '"Karla", sans-serif',
  },
  sizes: {},
  components: {
    Text: {
      variants: {
        feature: {
          fontWeight: "bold",
          color: "brand.500",
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      variants: {
        secondary: {
          bg: "#EBEFF9",
          color: "#0A3254",
          _hover: {
            bg: "#E6EAF5",
          },
        },
        solid: (props) => ({
          color: "white",
          _hover: {
            bg: "brand.500",
          },
          bg: props.colorMode === "dark" ? "brand.500" : "#3DB18A",
        }),
      },
    },
    Container: {
      baseStyle: {
        maxWidth: "760px",
      },
      variants: {
        nav: {
          bg: "#F8FAFF",
          py: "10",
          maxWidth: "100%",
        },
      },
    },
  },
});

export default theme;
